<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
              <c-label-text title="사업장" :value="equipData.plantName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비유형 -->
            <c-label-text title="설비유형" :value="equipData.equipmentTypeName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비관리번호 -->
            <c-label-text title="설비관리번호" :value="equipData.equipmentNo"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비명 -->
            <c-label-text title="설비명" :value="equipData.equipmentName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 설비 사양 -->
      <c-table
        ref="grid"
        title="설비 사양"
        tableId="grid"
        :columns="grid.columns"
        :data="grid.data"
        selection="multiple"
        rowKey="equipmentSpecId"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
                  <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow" />
                  <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleteSpec" />
                  <c-btn v-if="editable" label="저장" icon="save" @btnClicked="saveSpec" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-spec',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantCd: '',
        psmFlag: '',
      }),
    },
    equipData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        equipmentTypeCd: null,  // 설비유형 코드
        equipmentTypeName: '',  // 설비유형명
        equipmentCd: '',  // 설비코드
        equipmentNo: '',  // 설비코드
        equipmentName: '',  // 설비명
        mainProcessCd: '',  // 주공정
        subProcessCd: '',  // 부공정
        managementDepts: '',  // 관리부서
        equipmentLocation: '',  // 설비위치
        installDate: '',  // 설치일자
        installVendor: '',  // 설치업체
        opertationDate: '',  // 가동일자
        checkPassNo: '',  // 검사합격번호
        checkPassDate: '',  // 합격일자
        discardFlag: 'N',
        discardDate: '',  // 폐기일자
        psmFlag: 'N',  // PSM 대상 여부
        psmTypeCd: null,
        amount: '',  // 수량
        lawEquipmentFlag: 'N',  // 법정설비 여부
        inspectionCycleCd: null,  // 법정점검 주기
        selfCycleCd: null,  // 자체점검 주기
        pmCycleCd: null,  // 자체점검 주기
        pmFlag: 'N',  // 예방정비 여부
        cmFlag: 'N',  // 고장정비 여부
        predictiveFlag: 'N',  // 예지정비 여부
        operFlag: 'N', // 운전정비 여부
        relatedLaws: '',  // 관련법규
        recentInspectionDate: '',  // 최근 점검일자
        outsourcingVendor: '',  // 유지보수업체
        hazardousFlag: 'N'
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          {
            required: true,
            name: 'item',
            field: 'item',
            // 항목
            label: '항목',
            style: 'width:100px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'itemDesc',
            field: 'itemDesc',
            label: '항목설명',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'itemValue',
            field: 'itemValue',
            label: '값',
            type: 'text',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'itemUnit',
            field: 'itemUnit',
            // 단위
            label: '단위',
            type: 'text',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    param() {
      this.getList()
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.equipment.spec.list.url;
      this.saveUrl = transactionConfig.mdm.equipment.spec.save.url;
      this.deleteUrl = transactionConfig.mdm.equipment.spec.delete.url;
      this.getList();
    },
    getList() {
      if (this.popupParam.equipmentCd) {
        this.$http.url = this.listUrl;
        this.$http.param = {plantCd: this.popupParam.plantCd, equipmentCd: this.popupParam.equipmentCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    saveSpec() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getList();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addrow() {
      this.grid.data.push({
        editFlag: 'C',
        plantCd: this.popupParam.plantCd,
        equipmentCd: this.popupParam.equipmentCd,
        equipmentSpecId: uid(),
        orderNo: '0',
        item: '',
        itemDesc: '',
        itemValue: '',
        itemUnit: '',
      })
    },
    deleteSpec() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>